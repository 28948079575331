export const columns = [
    { field: 'reviewComment', headerName: 'Review Comment' },
    {
        field: 'isInsight',
        headerName: 'Is Insight',
    },
    {
        field: 'afbStatus',
        headerName: 'Afb Status'
    },
    {
        field: 'author_Name',
        headerName: 'Author',
    },
    {
        field: 'createdDate',
        headerName: 'Created Date',
    },
    {
        field: 'document',
        headerName: 'Document',
    },
    {
        field: 'delete',
        headerName: 'Delete',
    },
];
