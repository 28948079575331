import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IFormInputs } from '../../../types/shared';
import { Box, CircularProgress, Grid } from '@mui/material';
import { columns } from '../../../data/columns/risk-list';
import { useDataProcessingContext } from '../../../context/data-processing-context';
import { useUIControlContext } from '../../../context/ui-control-context';
import CustomButton from '../../../components/custom-button/custom-button';
import InfoPage from '../../info/info-page';
import CustomTable from '../../../components/custom-table/custom-table';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store';
import CustomDialog from '../../../components/custom-dialog/custom-dialog';
import TextInput from '../../../components/text-input/text-input';
import Toast from '../../../components/toast/toast';
import axios from 'axios';
import { ManagerData } from '../../../types/risk-detail';
import Loading from '../../loading/loading';
import { useSharedDataContext } from '../../../context/shared-data-context';

function RiskList() {
    const [riskData, setRiskData] = useState<ManagerData[]>([]);
    const { baseUrl, handleCreateEntity } = useSharedDataContext();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedManagerId, setSelectedManagerId] = useState("");
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<IFormInputs>({
        defaultValues: {
            category: 'Asset Manager',
            entityType: 'Administrator',
            entityName: '',
            searchValue: '',
        },
    });

    const { selected, setSelected } = useDataProcessingContext();
    const {
        loading,
        setLoading,
        alertSeverity,
        alertMessage,
        openToast,
        handleCloseToast,
        isDialogOpen,
        handleOpenDialog,
        handleCloseDialog,
        handleManageToast,
        errorMessage,
    } = useUIControlContext();

    const accessToken = useStore((state) => state.accessToken);
    const { handleLogOut } = useUIControlContext();

    const navigate = useNavigate();

    const handleNavigate = useCallback(
        (id: string | number) => {
            navigate(`/dqr/risk-list/${id}`);
        },
        [navigate]
    );

    const rows = useMemo(() => {
        return riskData.map(({ name, id }) => {
            return {
                id: id,
                name: name,
                productType: 'Asset Managemet',
            };
        });
    }, [riskData]);

    const onSubmitSearchRisk: SubmitHandler<IFormInputs> = useCallback(
        async ({ searchValue }) => {
            const url = `${baseUrl}Manager/Filter`;
            const data = {
                SearchText: searchValue?.toUpperCase(),
                pageSize: 100
            };
            setLoading(true);
            try {
                const response = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                setRiskData(response?.data?.data?.data);
                if (response?.data?.data?.data.length === 0) {
                    handleManageToast(
                        'error',
                        'There are no results with that criteria'
                    );
                }
                console.log('response risk list', response);
            } catch (error) {
                console.error('Error risk list request:', error);
                handleManageToast(
                    'error',
                    'There was an error trying to search for the manager'
                );
                const typedError = error as { response?: { status?: number } };
                console.error(
                    'Error fetching manager data:',
                    typedError.response
                );
                if (typedError.response?.status === 401) {
                    handleLogOut();
                }
            } finally {
                setLoading(false);
            }
        },
        [
            baseUrl,
            accessToken,
            setRiskData,
            setLoading,
            handleManageToast,
            handleLogOut,
        ]
    );
    useEffect(() => {
        if (!isDialogOpen) reset();
    }, [isDialogOpen, reset]);

    const handleOpenDeleteDialog = (managerId: string) => {
        setSelectedManagerId(managerId);
        setIsDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setSelectedManagerId("");
    };

    const handleDeleteManager = async () => {
        if (selectedManagerId) {
            console.log("Deleting manager with ID:", selectedManagerId);
            try {
                setLoading(true);
                const apiUrl = `${baseUrl}Manager/${selectedManagerId}`;
                const response = await axios.delete(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response) {
                    handleManageToast(
                        'success',
                        ' Manager deleted succesfully'
                    );
                    const updatedRiskData = riskData.filter(manager => manager.id !== selectedManagerId);
                    setRiskData(updatedRiskData);
                }
            } catch (error) {
                console.error("Error deleting manager:", error);
            }finally {
                setLoading(false);
                handleCloseDeleteDialog();
            }
        }
    };

    const actionsData = useMemo(
        () => [
            {
                status: 'NoResults',
                color: 'error',
                buttonText: 'Delete',
                actionType: 'reportListAction',
                actionEvent: (managerId: string) =>
                    handleOpenDeleteDialog(managerId),
            },
        ],
        []
    );

    interface DeleteConfirmationDialogProps {
        open: boolean;
        onClose: () => void;
        onConfirm: () => void;
        managerId: string;
    }

    const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
                                                                                   open,
                                                                                   onClose,
                                                                                   onConfirm,
                                                                                   managerId
                                                                               }) => {
        const managerName = riskData.filter(manager => manager.id !== selectedManagerId)[0]?.name;
        const actions = (
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <CustomButton text="Cancel" onClick={onClose} color="warning" variant="contained" />
                </Grid>
                <Grid item>
                    <CustomButton text="Delete" onClick={onConfirm} color="error" variant="contained" />
                </Grid>
            </Grid>
        );

        return (
            <CustomDialog
                open={open}
                onClose={onClose}
                title="Confirm Delete"
                errorMessage=""
                actions={
                    loading ? (
                        <></>
                    ) : (
                        actions
                    )
                }
            >
                <p>Are you sure you want to delete the manager with name: {managerName}?</p>
            </CustomDialog>
        );
    };

    if (loading && !isDialogOpen) return <Loading />;

    document.title = `AppHub - Dqr - Risk List`;
    return (
        <>
            <Box
                component={isDialogOpen ? 'section' : 'form'}
                sx={{ width: '100%' }}
                onSubmit={handleSubmit(onSubmitSearchRisk)}
            >
                <Grid
                    container
                    gap={1}
                    sx={{
                        width: '100%',
                        marginBottom: -3,
                        marginTop: 2,
                    }}
                >
                    <Grid item sx={{ flexGrow: 1 }}>
                        <TextInput
                            name='searchValue'
                            control={control}
                            labelName='Search by name'
                            ruleRequired={!isDialogOpen}
                            minLengthErrorValue={3}
                            minLengthError='Must be at least 3 characters'
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton
                            type='submit'
                            color='success'
                            variant='contained'
                            size='large'
                            text={'Search'}
                        />
                    </Grid>
                    <Grid item>
                        <CustomButton
                            type='button'
                            color='warning'
                            variant='contained'
                            size='large'
                            onClick={handleOpenDialog}
                            text={'New Manager'}
                        />
                    </Grid>
                </Grid>
                {loading && !isDialogOpen && <Loading />}
                <Grid container gap={2} flexDirection='column'>
                    {riskData.length > 0 ? (
                        <>
                            <Grid
                                item
                                container
                                sx={{ marginTop: '20px' }}
                                alignItems='center'
                            >
                                {riskData && riskData.length > 0 && (
                                    <Grid item sx={{ width: '100%' }}>
                                        <CustomTable
                                            handleNavigate={handleNavigate}
                                            useCheckbox={false}
                                            selected={selected}
                                            setSelected={setSelected}
                                            columns={columns}
                                            rows={rows}
                                            actionsData={actionsData}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <InfoPage
                            message={
                                'The are no manager to show, search for one...'
                            }
                        />
                    )}
                </Grid>
            </Box>
            <CustomDialog
                submitForm={handleSubmit(handleCreateEntity)}
                isAForm
                open={isDialogOpen as boolean}
                title='New Manager'
                onClose={handleCloseDialog}
                errorMessage={errorMessage}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialog}
                                text='CANCEL'
                            />
                            <CustomButton text='CREATE' type='submit' />
                        </>
                    )
                }
            >
                {loading ? (
                    <Box display='flex' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid
                        container
                        flexDirection={'column'}
                        gap={2}
                        marginTop={'10px'}
                    >
                        <Grid item>
                            <TextInput
                                error={!!errors.entityName}
                                ruleRequired
                                name='entityName'
                                control={control}
                                labelName='Manager Name'
                            />
                        </Grid>
                    </Grid>
                )}
            </CustomDialog>

            <DeleteConfirmationDialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleDeleteManager}
                managerId={selectedManagerId}
            />
            <Toast
                handleCloseToast={handleCloseToast as () => void}
                openToast={openToast as boolean}
                severity={alertSeverity}
                message={alertMessage}
            />
        </>
    );
}

export default RiskList;
