import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Control, Controller } from 'react-hook-form';
import { IFormInputs, Rows } from '../../../../types/shared';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    styled,
} from '@mui/material';
import { columns } from '../../../../data/columns/file-upload/file-upload';
import InfoPage from '../../../info/info-page';
import dayjs, { Dayjs } from 'dayjs';
import CustomTable from '../../../../components/custom-table/custom-table';
import TextInput from '../../../../components/text-input/text-input';
import { UploadFileData } from '../../../../types/risk-detail';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import CustomDialog from '../../../../components/custom-dialog/custom-dialog';
import CustomButton from '../../../../components/custom-button/custom-button';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    control: Control<IFormInputs>;
    searchValue?: string;
    searchByDateOfUploadedFile?: Dayjs | null;
    showDueDiligence?: string;
    uploadFilesData?: UploadFileData[];
    loadingData: boolean;
    handleDeleteUploadedFile: (id: string) => void;
    selected: GridRowSelectionModel;
    setSelected: React.Dispatch<
        React.SetStateAction<GridRowSelectionModel | []>
    >;
    isDialogOpen?: boolean;
    handleCloseDialog: () => void;
    handleOpenDialog: () => void;
    loading?: boolean;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function FileUpload({
                        control,
                        searchValue,
                        loadingData,
                        uploadFilesData,
                        handleDeleteUploadedFile,
                        selected,
                        setSelected,
                        loading,
                    }: Props) {
    const [uploadFilesfilteredArray, setUploadFilesFilteredArray] = useState<
        UploadFileData[]
    >([]);
    const [fileToDelete, setFileToDelete] = useState<string | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleFilterUploadFiles = useCallback(() => {
        if (!searchValue) {
            setUploadFilesFilteredArray(uploadFilesData as UploadFileData[]);
            return;
        }
        const searchValueLower = searchValue?.toLowerCase().replace(/\s/g, '');

        const filtered = uploadFilesData?.filter((file) => {
            const originalNameLower = file.originalName
                .toLowerCase()
                .replace(/\s/g, '');
            const authorNameLower = file.author_Name
                .toLowerCase()
                .replace(/\s/g, '');

            return (
                originalNameLower.includes(searchValueLower as string) ||
                authorNameLower.includes(searchValueLower as string)
            );
        });

        setUploadFilesFilteredArray(filtered as UploadFileData[]);
    }, [uploadFilesData, searchValue, setUploadFilesFilteredArray]);

    const handleOpenDialog = (id: string) => {
        setFileToDelete(id);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setFileToDelete(null);
    };

    const actionsData = useMemo(
        () => [
        ],
        []
    );

    const rows = useMemo(() => {
        return uploadFilesfilteredArray?.map(
            ({ originalName, createdDate, author_Name, id, filePath }) => {
                return {
                    id: id,
                    name: originalName,
                    informationEffectiveDate:
                        dayjs(createdDate).format('DD/MM/YYYY'),
                    authors: author_Name,
                    file: filePath ? (
                        <IconButton
                            onClick={() => {
                                if (filePath && filePath.startsWith('http')) {
                                    window.open(filePath, '_blank');
                                }
                            }}
                        >
                            <PictureAsPdfIcon />
                        </IconButton>
                    ) : "None",
                    delete:
                        <IconButton
                            onClick={() => handleOpenDialog(id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                };
            }
        );
    }, [uploadFilesfilteredArray]);

    useEffect(() => {
        setSelected([]);
    }, [setSelected]);

    useEffect(() => {
        handleFilterUploadFiles();
    }, [handleFilterUploadFiles]);

    useEffect(() => {
        console.log("uploadFilesfilteredArray", uploadFilesfilteredArray);
    }, [uploadFilesfilteredArray]);

    return (
        <Grid
            container
            flexDirection={'column'}
            gap={3}
            sx={{ width: '100%'}}
        >
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Controller
                        name={'uploadedFiles'}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            const handleFileChange = (
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const files: unknown = e.target.files;

                                onChange([
                                    ...(value as FileList[]),
                                    ...(files as FileList[]),
                                ]);
                            };
                            return (
                                <Button
                                    component='label'
                                    variant='contained'
                                    color='warning'
                                    size='large'
                                    startIcon={<CloudUploadIcon />}
                                    disabled={loadingData}
                                    style={{ width: '200px' }}
                                >
                                    Upload file
                                    <VisuallyHiddenInput
                                        onChange={handleFileChange}
                                        type='file'
                                        multiple
                                    />
                                </Button>
                            );
                        }}
                    />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                    <TextInput
                        name='searchValue'
                        control={control}
                        labelName={'Search by document name or author '}
                    />
                </Grid>
            </Grid>

            <Grid item container>
                {uploadFilesfilteredArray && uploadFilesfilteredArray.length > 0 ? (
                    <CustomTable
                        useCheckbox={false}
                        selected={selected}
                        setSelected={setSelected}
                        columns={columns}
                        rows={rows as Rows[]}
                        actionsData={actionsData}
                        isSelectable
                        defaultRowsPerPage={5}
                    />
                ) : (
                    <InfoPage
                        minHeight='0'
                        message={'There are no files'}
                    />
                )}
            </Grid>

            <CustomDialog
                open={isDialogOpen as boolean}
                title='Erase file'
                text='Are you sure you want to erase it?'
                onClose={handleCloseDialog}
                actions={
                    loading ? (
                        <></>
                    ) : (
                        <>
                            <CustomButton
                                onClick={handleCloseDialog}
                                text='CANCEL'
                            />
                            <CustomButton
                                onClick={() =>
                                    handleDeleteUploadedFile &&
                                    handleDeleteUploadedFile(
                                        selected ? (selected[0] as string) : ''
                                    )
                                }
                                text='yes'
                            />
                        </>
                    )
                }
            >
                {loading ? (
                    <Box display='flex' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <></>
                )}
            </CustomDialog>
        </Grid>
    );
}

export default FileUpload;
